import { ReactComponent as Discord } from './assets/icon_clyde_white_RGB.svg';
import { ReactComponent as Twitter } from './assets/Twitter_bird_logo_black.svg';
import { ReactComponent as Instagram } from './assets/Instagram_font_awesome.svg';
import { ReactComponent as Telegram } from './assets/telegram.svg';
import { ReactComponent as Youtube } from './assets/YouTube_full-color_icon_(2017).svg';
import { ReactComponent as TikTok } from './assets/Ionicons_logo-tiktok.svg';
import { ReactComponent as Twitch } from './assets/Twitch_Glitch_Logo_Black.svg';
export default function Contact() {
    return (
        <>
            <div className="flex justify-center">
                <iframe src="https://player.twitch.tv/?channel=gariyuu&parent=www.gariyuu.com&parent=gariyuu.com" frameborder="0" allowfullscreen="true" scrolling="no" height="378" width="620"></iframe>
            </div>
            <div className="flex flex-wrap justify-center">
                {/* Discord */}
                <a className='p-5 m-2 hover:bg-zinc-900 rounded-full' href='https://discord.gg/MZJpxFJSJz'>
                    <Discord className='w-10 h-10 md:w-20 md:h-20 max-w-none'/>
                </a>
                {/* Twitter */}
                <a className='text-white p-5 m-2 hover:bg-zinc-900 rounded-full' href='https://twitter.com/ggariyuu'>
                    <Twitter className='w-10 h-10 md:w-20 md:h-20 max-w-none'/>
                </a>

                {/* Instagram */}
                <a className='text-white p-5 m-2 hover:bg-zinc-900 rounded-full' href='https://twitter.com/ggariyuu'>
                    <Instagram className='w-10 h-10 md:w-20 md:h-20 max-w-none'/>
                </a>

                {/* Telegram */}
                <a className='text-white p-5 m-2 hover:bg-zinc-900 rounded-full' href='https://t.me/ggariyuu'>
                    <Telegram className='w-10 h-10 md:w-20 md:h-20 max-w-none'/>
                </a>

                {/* Youtube */}
                <a className='text-white p-5 m-2 hover:bg-zinc-900 rounded-full' href='https://www.youtube.com/gariyuutube'>
                    <Youtube className='w-10 h-10 md:w-20 md:h-20 max-w-none'/>
                </a>

                {/* TikTok */}
                <a className='text-white p-5 m-2 hover:bg-zinc-900 rounded-full' href='https://www.tiktok.com/@gariyuu'>
                    <TikTok className='w-10 h-10 md:w-20 md:h-20 max-w-none'/>
                </a>

                {/* Twitch */}
                <a className='text-white p-5 m-2 hover:bg-zinc-900 rounded-full' href='https://www.twitch.tv/gariyuu'>
                    <Twitch className='w-10 h-10 md:w-20 md:h-20 max-w-none'/>
                </a>
            </div>
        </>
    )
}