/* eslint import/no-webpack-loader-syntax: off */
import { useMemo, useRef, useState } from 'react'
import ImageModal from './modal'
import justifiedLayout from 'justified-layout'
import useSize from '@react-hook/size'

const images = [
    { href: require('./assets/3-20-23 web.png'), width: 1280, height: 1258 },
    { href: require('./assets/Morghus Birthday 2023 for posting.png'), width: 1630, height: 917 },
    { href: require('./assets/painted hoodie web.png'), width: 960, height: 1280 },
    { href: require('./assets/1-16-2023 web.png'), width: 887, height: 1280 },
    { href: require('./assets/blue fangs web.png'), width: 1280, height: 853 },
    { href: require('./assets/thresh speedpaint web.png'), width: 1280, height: 853 },
    { href: require('./assets/rawrg web.png'), width: 1280, height: 853 },
    { href: require('./assets/edgy cropped web.png'), width: 1280, height: 1280 },
    { href: require('./assets/eat them web.png'), width: 1133, height: 1280 },
    { href: require('./assets/balrog web.png'), width: 1133, height: 774 },
]

export default function Gallery() {
    const [openImageIndex, setOpenImageIndex] = useState(null)
    const [dialogueOpen, setDialogueOpen] = useState(false)
    const containerRef = useRef(null)
    const [containerWidth] = useSize(containerRef)
    const layout = useMemo(() => {
        return justifiedLayout(images, { containerWidth })
    }, [containerWidth])
    return (
        <>
            <ImageModal
                open={dialogueOpen}
                setOpen={() => setDialogueOpen(false)}
                image={openImageIndex !== null && images[openImageIndex]}
            />

            <div className='relative' ref={containerRef}>
                {images.map((item, i) => {
                    const l = layout.boxes[i]
                    return (<div>
                        <img className="absolute rounded-lg" src={item.href} alt="" style={{ width: l.width, height: l.height, top: l.top, left: l.left }}
                            onClick={() => { setOpenImageIndex(i); setDialogueOpen(true) }}
                        />
                    </div>)
                })
                }
            </div>
        </>
    )
}